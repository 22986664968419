.dashboard {
  height: 100dvh;
  .order-details {
    p {
      font-size: 1.5rem;
    }
    strong {
      font-size: 2rem;
    }
  }
  img {
    width: 45%;
    align-self: center;
    max-width: 300px;
  }
}
