@import "../utils/styles.scss";

.otp-input {
  width: 2.5rem !important;
  height: 2.5rem !important;
  padding: 0;
  background-image: none !important;
  padding-right: 0 !important;
}

// MODAL

.overlay {
  position: fixed;
  top: 0;
  width: $max-width;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  align-self: center;
}

.modal-container {
  max-width: $max-width;
  width: 100vw;
  height: 70%;
  background-color: #fff;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  border-radius: 12px 12px 0 0px;
  overflow: hidden;
}
