$max-width: 550px;
$background-dark: #ebf6e5;
$background: #fbfdf9;
$text-color: #374256;
$primary-btn: #37a802;
$primary: #37a802;
$danger: #d35653;

// $theme-colors: (
//   "primary": #37a802,
// );
.btn-primary {
  color: #fff !important;
}
.btn-outlined-primary {
  color: $primary !important;
  border-color: $primary !important;
  &:hover {
    background-color: $primary !important;
    color: #fff !important;
    font-weight: 700 !important;
  }
}
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
