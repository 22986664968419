@import "../../utils/styles.scss";

.payment {
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .box {
    background-color: #fff;
    padding: 0.5rem 0;
    border-radius: 10px;
    margin: 0.75rem 0;
    .email {
      font-weight: 500;
    }
    .flex-center {
      display: flex;
      padding: 0 1rem;
      align-items: center;
    }
    .footer {
      margin-top: 0.5rem;
      padding-top: 0.5rem;
      border-top: 1px solid #f1f1f1;
    }
  }

  .cashback {
    font-size: 0.8rem;
    font-weight: 700;
    .green {
      color: $primary;
    }
  }
}
.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(100%);
  transition: all 800ms ease-in-out;
  background-color: #00000025 !important;
  max-width: $max-width;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(100%);
}

.modal-ctn {
  background-color: $background;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 2rem 2rem 0 0;
  height: 100%;
  max-height: 90%;
}
